import React, { useState, useEffect } from "react";
import {
  getDefaultValuesForRecordType,
  getFieldsForRecordType,
  getLeafFieldsForRecordType,
  getPageTitle,
  isTransactionEntity,
} from "../../services/standardEntityFieldService";
import { apiCall } from "../../services/api";
import { useHistory, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import ConfirmationButtonComponent from "../buttons/ConfirmationButtonComponent";
import CircularProgress from "@mui/material/CircularProgress";
import withAuth from "../../hocs/withAuth";
import { Link } from "react-router-dom";
import _, { debounce } from "lodash";
import DeleteButtonComponent from "../buttons/DeleteButtonComponent";
import { FormInputField } from "../inputFields/FormInputField";
import { isLocatorAccessible, isValidRecord } from "../../services/utils";
import { canEditEntity } from "../../services/utils";
import { Field } from "../../types/field";
import { useSnackBar } from "../providers/SnackBarProvider";

function RecordAdditionForm({
  fields = [],
  formMinWidth = "40vw",
  formMinHeight = "82vh",
  entityType,
  isValidEntityRecord = () => {
    return true;
  },
  handleEntitySpecificChange = async (params) => {},
  handleEntityDecoration = (record) => {
    return record;
  },
  isUnSubmitRequired = (isApproved) => {
    return isApproved;
  },
  formDisabled = (record) => false,
  canDelete = (recordType, currentUser, record) => !isTransactionEntity(recordType),
  originalPageTitle = "",
  currentUser,
  originalRecord = null,
  originalIsUpdateForm = false,
}) {
  let { id, recordType }: any = useParams();
  recordType = entityType ? entityType : recordType;
  if (_.isEmpty(fields)) {
    fields = getFieldsForRecordType(recordType);
  }
  let initialRecord = originalRecord != null ? originalRecord : { ...getDefaultValuesForRecordType(recordType), id: null, cancelled: false };

  let defaultValues = getDefaultValuesForRecordType(recordType);
  const { dispatch: snackBarDispatch } = useSnackBar();
  const [error, setError] = useState(null);
  const [record, setRecord] = useState(initialRecord);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  const history = useHistory();

  const isUpdateForm = originalIsUpdateForm || id != null;

  let entityPageTitle = "";
  if (_.isEmpty(originalPageTitle)) {
    entityPageTitle = getPageTitle(recordType);
  }
  let pageTitle = originalPageTitle ? originalPageTitle : isUpdateForm ? `Update ${entityPageTitle}` : `Add ${entityPageTitle}`;

  useEffect(() => {
    if (!canEditEntity(recordType, currentUser)) {
      history.push("/");
    }
    if (originalRecord) {
      return;
    }
    setRecord({ ...defaultValues, id: null, cancelled: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordType, currentUser, history]);

  useEffect(() => {
    if (_.isEmpty(id)) {
      return;
    }
    fetchRecord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, recordType]);

  if (isTransactionEntity(recordType) && record) {
    if (!isLocatorAccessible(record.docLocatorCode)) {
      history.push("/");
    }
  }

  const setSnackBarMessage = (message, severity = "success") => {
    snackBarDispatch({ type: "set", message, severity });
    console.log(message);
  };
  function fetchRecord() {
    setLoading(true);
    apiCall("get", `/api/entity/${recordType}/${id}`)
      .then((response) => {
        if (response == null) {
          console.warn("Null response for records fetch");
          return;
        }
        if (isTransactionEntity(recordType) && response) {
          if (!isLocatorAccessible(response.docLocatorCode)) {
            setSnackBarMessage("You are not authorized to view this record", "error");
            history.push("/");
            return;
          }
        }
        setRecord({ ...response });
        setIsApproved(response.approved);
      })
      .catch((err) => {
        console.error("Error fetching records", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function handleChange(e, field: Field, handleRecordChange) {
    const { name, value, type, checked }: any = { ...e.target };
    setIsEdited(true);
    setSnackBarMessage(null);
    await handleEntitySpecificChange({
      record: { ...record },
      name,
      value,
      type,
      checked,
      setFormErrors,
      setError,
      handleRecordChange,
    });
  }

  const handleSubmit = (record) => {
    if (!isValidateRecord(record)) {
      return;
    }

    if (isUpdateForm) {
      updateRecord(record);
    } else {
      saveNewRecord(record);
    }
  };

  const isValidateRecord = (record) => {
    return isValidRecord({
      record,
      leafFields: getLeafFieldsForRecordType(recordType),
      setError,
      setFormErrors,
      isValidEntityRecord,
    });
  };

  const handleUnapprove = debounce((record) => {
    setLoading(true);
    apiCall("put", `/api/entity/${recordType}/${record.id}/toggle?approved=false`, record)
      .then((response) => {
        setSnackBarMessage("Record Updated Successfully");
        history.push(`/${recordType}/view/${id}`);
      })
      .catch((err) => {
        console.error("error inside unapproval", err);
        setSnackBarMessage("Error Occured While Un-Submitting", "error");
        setError(err.message || err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 200);

  const updateRecord = debounce((record) => {
    setLoading(true);
    let finalRecord = handleEntityDecoration(record);
    apiCall("put", `/api/entity/${recordType}/${record.id}`, finalRecord)
      .then((response) => {
        setSnackBarMessage("Record Updated Successfully");
        history.push(id ? `/${recordType}/view/${id}` : `/${recordType}/view`);
      })
      .catch((err) => {
        console.error("error inside calculator", err);
        setSnackBarMessage("Error Occured While Updating", "error");
        setError(err.message || err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 200);

  const saveNewRecord = debounce((record) => {
    let finalRecord = handleEntityDecoration(record);
    setLoading(true);
    apiCall("post", `/api/entity/${recordType}/`, finalRecord)
      .then((response) => {
        let id = response.id;
        setSnackBarMessage(`Record Created Successfully`);
        history.push(`/${recordType}/view/${id}`);
      })
      .catch((err) => {
        console.error("error inside save new record", err);
        setSnackBarMessage("Error Occured While Saving", "error");
        setError(err.message || err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 200);

  const handleDeleteRecord = () => {
    setLoading(true);
    apiCall("delete", `/api/entity/${recordType}/${record.id}`)
      .then((response) => {
        setSnackBarMessage("Record Deleted Successfully");
        history.push(`/${recordType}/view`);
      })
      .catch((err) => {
        console.error("[RecordAdditionForm] error inside handle delete ", err);
        setSnackBarMessage("Error Occured While Deleting", "error");
        setError(err.message || err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  let globalConstants = { ...record };
  //todo: govern this by global constants kind of structure
  if (recordType === "material-inward") {
    globalConstants["invByBasic"] = 1;
    if (record && record["inventoryValue"] && record["basicValue"]) {
      let dividend = parseFloat(record["inventoryValue"]);
      let divisor = parseFloat(record["basicValue"]);
      if (!isNaN(dividend) && !isNaN(divisor) && divisor !== 0) {
        globalConstants["invByBasic"] = dividend / divisor;
      }
    }
  }

  const handleKeyPress = (event) => {
    try {
      if (event.key === "Enter") {
        var form = event.target.form;
        var index = Array.prototype.indexOf.call(form, event.target);
        form.elements[index + 2].focus();
        event.preventDefault();
      }
    } catch (e) {
      console.error("[RecordAdditionForm] Exception occured while handling key press", e);
    }
  };

  return (
    <div>
      <Box sx={{ marginTop: 1.5 }}>
        <form onKeyDown={handleKeyPress}>
          <Stack direction="column" alignItems="center" spacing={2}>
            <h4 className="sub-header"> {pageTitle}</h4>
            <Paper
              sx={{
                minWidth: formMinWidth,
                minHeight: formMinHeight,
                backgroundColor: "#F7F6FF",
              }}
              className="flexPaper"
              elevation={10}
            >
              <Stack sx={{ mt: 4, mb: 4 }} direction="column" alignItems="center" spacing={2}>
                {loading ? (
                  <Box sx={{ ml: 22, display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  fields.map((field, index) => (
                    <FormInputField
                      key={index}
                      field={field}
                      leafFields={getLeafFieldsForRecordType(recordType)}
                      record={record}
                      setRecord={setRecord}
                      isUpdateForm={isUpdateForm}
                      formDisabled={formDisabled(record)}
                      formApproved={isUnSubmitRequired(isApproved)}
                      formCancelled={record.cancelled}
                      handleChangeInternal={handleChange}
                      formErrors={formErrors}
                      setFormErrors={setFormErrors}
                      entityType={recordType}
                      setError={setError}
                      globalConstants={globalConstants}
                    />
                  ))
                )}
              </Stack>
            </Paper>
            {error && (
              <Alert
                onClose={() => {
                  setError(null);
                  snackBarDispatch({ type: "clear" });
                }}
                severity="error"
              >
                {error}
              </Alert>
            )}
            {!loading && (
              <Stack direction="row" alignItems="center" spacing={2}>
                {!isEdited ? (
                  <Button variant="contained" color="error" component={Link} to={`/${recordType}/view`} disabled={loading}>
                    Back
                  </Button>
                ) : (
                  <ConfirmationButtonComponent
                    handleSubmit={() => (isUpdateForm ? history.push(`/${recordType}/view/${id}`) : history.push(`/${recordType}/view/`))}
                    confirmationTitle="Back"
                    confirmationBody={`Are You Sure,  All Your Unsaved Changes Will Be Lost`}
                    submitBody={record}
                    buttonText={"Back"}
                    color="error"
                    disabled={loading}
                  />
                )}
                {isUpdateForm && canDelete(recordType, currentUser, record) && (
                  <DeleteButtonComponent handleDelete={handleDeleteRecord} itemType={recordType} disabled={loading} />
                )}
                {isUnSubmitRequired(isApproved) ? (
                  <ConfirmationButtonComponent
                    handleSubmit={handleUnapprove}
                    confirmationTitle="UnSubmit"
                    confirmationBody="Are You Sure You Wish To Un-Submit/Un-Approve"
                    submitBody={record}
                    buttonText={"UnSubmit"}
                    disabled={loading}
                  />
                ) : (
                  <ConfirmationButtonComponent
                    handleSubmit={handleSubmit}
                    confirmationTitle="Save Record"
                    confirmationBody="Are You Sure You Wish To Proceed?"
                    submitBody={record}
                    buttonText={isUpdateForm ? "Update" : "Save"}
                    disabled={!isEdited || loading}
                    shouldOpen={isValidateRecord}
                  />
                )}
              </Stack>
            )}
          </Stack>
          <h4> </h4>
        </form>

        {/* {snackBarMessage !== null && (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {snackBarMessage}
          </Alert>
        )} */}
      </Box>
    </div>
  );
}

export default withAuth(RecordAdditionForm);
