//todo: move all this to specific handlers
import { nullSafeString } from "./stringUtils";
import { formatDate } from "./dateUtils";
import {
  materialTypeValues,
  inTransactionTypeValues,
  receiverDocTypeValues,
  transportModeValues,
  senderDocTypeValues,
} from "../standardEntities/materialInward";

import { locatorTypeValues } from "../standardEntities/locator";
import { projectTypeValues } from "../standardEntities/project";
import { workTypeValues } from "../standardEntities/cbSheetFields";
import { translatePicklistValue } from "./fieldsUtils";
import { outTransactionTypeValues } from "../standardEntities/materialOutward";
import { Field } from "../types/field";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import _ from "lodash";
import { safeConvertToFixed } from "./numberUtils";

const options = {
  margin: { top: 40 },
  styles: {
    fontSize: 11,
    font: "Arial",
    lineWidth: 0.75,
  },
  columnStyles: {
    0: { fontStyle: "underline", textColor: [0, 0, 0] },
  },
  bodyStyles: { valign: "top" },
  columnWidth: "auto",
  theme: "plain",
  tableLineColor: [128, 128, 128],
};

export const generatePDFContent = (fields: Field[], record, recordType) => {
  let title = [[""]];
  let senderReceiverData = [[""]];
  let dtHead = [];
  let details = [];
  let invoiceValues = [[""]];
  let remarks = [[""]];
  let otherValues = [[""]];
  if (recordType === "material-inward") {
    title = [["MATERIAL INWARD RECEIPT"]];
    senderReceiverData = [
      [
        `MIR No: ${record.id} \nMIR Date: ${formatDate(record.docDate)} \nMaterial Type: ${translatePicklistValue(
          record.materialType,
          materialTypeValues
        )}\nTrans Type: ${translatePicklistValue(record.transactionType, inTransactionTypeValues)}\nRecv Doc: ${translatePicklistValue(
          record.receiverDocType,
          receiverDocTypeValues
        )} \nDoc No: ${nullSafeString(record.receiverDocNo)} \nDoc Date: ${formatDate(record.receiverDocDate)} `,
        `Receiver: ${record.docOwnerName} (${record.docOwnerCode})\n${record.docAddress1} \n${nullSafeString(record.docAddress2)} \nStation: ${
          record.docStationName
        } (${record.docStateName}) \t\tGST No: ${nullSafeString(record.docGSTNo)} \nLocator: ${record.docLocatorName} (${
          record.docLocatorCode
        }) \t\tLocator Type: ${translatePicklistValue(record.docLocatorType, locatorTypeValues)} \nVendor: ${record.docCompanyName} (${
          record.docCompanyCode
        }) \nProject: ${record.projectName} (${record.projectCode}) \t\tVirtual Locator: ${nullSafeString(record.virtualLocator)}`,
      ],
      [
        `Total Packages: ${record.totalPkgs} \nInvoice Value: ${record.totalValue} \nInventory Value: ${
          record.inventoryValue
        }  \nSender Doc: ${translatePicklistValue(record.senderDocType, senderDocTypeValues)} \nDoc No: ${nullSafeString(
          record.senderDocNo
        )} \nDoc Date: ${formatDate(record.senderDocDate)} \nSender Material: ${translatePicklistValue(
          nullSafeString(record.senderMaterialType ?? record.materialType),
          materialTypeValues
        )}`,
        `Sender: ${record.senderLocatorType === "CS" || record.senderLocatorType === "PS" ? record.senderOwnerName : record.senderCompanyName} (${
          record.senderLocatorType === "CS" || record.senderLocatorType === "PS" ? record.senderOwnerCode : record.senderCompanyCode
        }) \n${record.senderAddress1} \n${nullSafeString(record.senderAddress2)} \nStation: ${record.senderStationName} (${
          record.senderStateName
        }) \t\tGST No: ${nullSafeString(record.senderGSTNo)} \nLocator: ${record.senderLocatorName} (${
          record.senderLocatorCode
        }) \t\tLocator Type: ${translatePicklistValue(record.senderLocatorType, locatorTypeValues)} \nVendor: ${nullSafeString(
          record.senderCompanyName
        )} (${nullSafeString(record.senderCompanyCode)}) \nProject: ${nullSafeString(record.senderProjectName)} (${nullSafeString(
          record.senderProjectCode
        )}) \t\tVirtual Locator: ${nullSafeString(record.senderVirtualLocator)} `,
      ],
    ];
    dtHead = [["SN", "Item Id", "Description Of Material", "Part Code", "Recvd Qty", "UOM", "Basic Value"]];
    details = record.details.map((row, index) => {
      return [index + 1, row.itemId, row.itemName, row.itemCustCode, row.totalQuantity, row.uom, row.basicAmount];
    });
    let totalInwardQuantity = record.details.reduce((total, detail) => {
      return total + parseFloat(detail.totalQuantity);
    }, 0);
    totalInwardQuantity = Math.round(totalInwardQuantity * 100) / 100;
    details.push(["", "", "", "SubTotal", safeConvertToFixed(totalInwardQuantity, 1), "", safeConvertToFixed(record.basicValue)]);
    invoiceValues = [
      [
        `IGST Value:\nCGST Value:\nSGST Value:\nFreight Value:`,
        `${safeConvertToFixed(record.iGSTValue, 2)}\n${safeConvertToFixed(record.cGSTValue, 2)}\n${safeConvertToFixed(
          record.sGSTValue,
          2
        )}\n${safeConvertToFixed(record.freightValue, 2)}`,
      ],
      [
        `                                                                                  Total Value:`,
        `${safeConvertToFixed(record.totalValue, 2)}`,
      ],
    ];
    remarks = [[`Remarks: ${nullSafeString(record.remarks)}`]];
    otherValues = [
      [
        `Gate Entry No: ${nullSafeString(record.gateEntryNo)} \nGate Entry Date: ${formatDate(record.gateEntryDate)} \nVehicle No: ${nullSafeString(
          record.vehicleNo
        )}\t\t\t\t\t\t\t\t\t\t`,
        `Transport Mode: ${translatePicklistValue(record.transportMode, transportModeValues)} \nTransport Vendor: ${
          record.vendorName
        } (${nullSafeString(record.vendorCode)}) \nConsignment Note: ${nullSafeString(record.consignmentNote)}\t\t\t\t\t\t\t\t\t\t`,
        `Status: ${record.submit ? "Submitted" : record.cancelled ? "Cancelled" : "Submit Pending"} \nEway Bill: ${nullSafeString(
          record.ewayBillNo
        )}\t\t\t\t\t\t\t\t\t\t`,
      ],
    ];

    return {
      rows: [
        { headers: title, halign: "center" },
        { body: senderReceiverData },
        { headers: dtHead, body: details, halign: "center" },
        { body: invoiceValues, halign: "right" },
        { body: remarks, halign: "left" },
        { body: otherValues },
      ],
      footer: "ONLY FOR INTERNAL USE",
    };
  } else if (recordType === "material-outward") {
    title = [["MATERIAL OUTWARD AUTHORIZATION"]];
    senderReceiverData = [
      [
        `MOA No: ${record.id} \nMOA Date: ${formatDate(record.docDate)} \nMaterial Type: ${translatePicklistValue(
          record.materialType,
          materialTypeValues
        )}\nProject Type: ${translatePicklistValue(record.projectType, projectTypeValues)}\nSender Doc: ${translatePicklistValue(
          record.senderDocType,
          senderDocTypeValues
        )}\nDoc No: ${nullSafeString(record.senderDocNo)} \nDoc Date: ${formatDate(record.senderDocDate)} `,
        `Sender: ${record.docOwnerName} (${record.docOwnerCode}) \n${record.docAddress1} \n${nullSafeString(record.docAddress2)} \nStation: ${
          record.docStationName
        } (${record.docStateName}) \t\tGST No: ${nullSafeString(record.docGSTNo)} \nLocator: ${record.docLocatorName} (${
          record.docLocatorCode
        }) \t\tLocator Type: ${translatePicklistValue(record.docLocatorType, locatorTypeValues)} \nVendor: ${record.docCompanyName} (${
          record.docCompanyCode
        }) \nProject: ${record.projectName} (${record.projectCode}) \t\tVirtual Locator: ${record.virtualLocator}`,
      ],
      [
        `Total Value: ${safeConvertToFixed(record.totalValue, 2)} \nTrans Type: ${translatePicklistValue(
          record.transactionType,
          outTransactionTypeValues
        )}\n\nRecv Doc: ${translatePicklistValue(record.receiverDocType, receiverDocTypeValues)} \nDoc No: ${nullSafeString(
          record.receiverDocNo
        )} \nDoc Date: ${formatDate(record.receiverDocDate)} \nReceiver Material: ${translatePicklistValue(
          nullSafeString(record.receiverMaterialType ?? record.materialType),
          materialTypeValues
        )}`,
        `Receiver: ${
          record.receiverLocatorType === "CS" || record.receiverLocatorType === "PS" ? record.receiverOwnerName : record.receiverCompanyName
        } (${record.receiverLocatorType === "CS" || record.receiverLocatorType === "PS" ? record.receiverOwnerCode : record.receiverCompanyCode}) \n${
          record.receiverAddress1
        } \n${nullSafeString(record.receiverAddress2)} \nStation: ${record.receiverStationName} (${
          record.receiverStateName
        }) \t\tGST No: ${nullSafeString(record.receiverGSTNo)} \nLocator: ${record.receiverLocatorName} (${
          record.receiverLocatorCode
        }) \t\tLocator Type: ${translatePicklistValue(record.receiverLocatorType, locatorTypeValues)} \nVendor: ${nullSafeString(
          record.receiverCompanyName
        )} (${nullSafeString(record.receiverCompanyCode)}) \nProject: ${nullSafeString(record.receiverProjectName)} (${nullSafeString(
          record.receiverProjectCode
        )}) \t\tVirtual Locator: ${nullSafeString(record.receiverVirtualLocator)} `,
      ],
    ];

    dtHead = [["SN", "Item Id", "Description Of Material", "Part Code", "Out Qty", "UOM", "Value", "MIR No", "Row No"]];

    details = record.details.map((row, index) => {
      return [
        index + 1,
        row.itemId,
        row.itemName,
        row.itemCustCode,
        safeConvertToFixed(row.totalQuantity, 1),
        row.uom,
        safeConvertToFixed(row.totalAmount, 2),
        row.mirId,
        row.mirRowId,
      ];
    });

    let totalDetailQuantity = record.details.reduce((total, detail) => {
      return total + parseFloat(detail.totalQuantity);
    }, 0);
    totalDetailQuantity = Math.round(totalDetailQuantity * 100) / 100;
    details.push(["", "", "", "Totals", safeConvertToFixed(totalDetailQuantity, 1), "", safeConvertToFixed(record.totalValue, 2), "", ""]);

    remarks = [[`Remarks: ${nullSafeString(record.remarks)}`]];
    otherValues = [
      [
        `Request By: ${nullSafeString(record.requestBy)} \nRequest Ref No: ${nullSafeString(
          record.requestRefNo
        )} \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t`,
        `Status: ${record.submit ? "Submitted" : record.cancelled ? "Cancelled" : "In Process"}\nGate Pass Made: ${
          record.gpFlg ? "Yes" : "No"
        }\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t`,
        `Gate Pass No: ${nullSafeString(record.gpId)} \nGate Pass Date: ${formatDate(record.gpDate)}\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t`,
      ],
    ];

    return {
      rows: [
        { headers: title, halign: "center" },
        { body: senderReceiverData, halign: "left" },
        { headers: dtHead, body: details, halign: "center" },
        { body: remarks, halign: "left" },
        { body: otherValues },
      ],
      footer: "ONLY FOR INTERNAL USE",
    };
  } else if (recordType === "gate-pass") {
    title = [["GATE PASS"]];
    senderReceiverData = [
      [
        `Gate Pass No: ${record.id} \nGate Pass Date: ${formatDate(record.docDate)} \nMaterial Type: ${translatePicklistValue(
          record.materialType,
          materialTypeValues
        )}\nProject Type: ${translatePicklistValue(record.projectType, projectTypeValues)}\nSender Doc: ${translatePicklistValue(
          record.senderDocType,
          senderDocTypeValues
        )}\nDoc No: ${nullSafeString(record.senderDocNo)} \nDoc Date: ${formatDate(record.senderDocDate)} `,
        `Sender: ${record.docOwnerName} (${record.docOwnerCode}) \n${record.docAddress1} \n${nullSafeString(record.docAddress2)} \nStation: ${
          record.docStationName
        } (${record.docStateName}) \t\tGST No: ${nullSafeString(record.docGSTNo)} \nLocator: ${record.docLocatorName} (${
          record.docLocatorCode
        }) \t\tLocator Type: ${translatePicklistValue(record.docLocatorType, locatorTypeValues)} \nVendor: ${nullSafeString(
          record.docCompanyName
        )} (${nullSafeString(record.docCompanyCode)}) \nProject: ${record.projectName} (${record.projectCode}) \t\tVirtual Locator: ${nullSafeString(
          record.virtualLocator
        )}`,
      ],
      [
        `MOA No: ${record.moaId} \nMOA Date: ${formatDate(record.moaDate)} \nTrans Type: ${translatePicklistValue(
          record.transactionType,
          outTransactionTypeValues
        )}\nRecv Doc: ${translatePicklistValue(record.receiverDocType, receiverDocTypeValues)} \nDoc No: ${nullSafeString(
          record.receiverDocNo
        )}\nDoc Date: ${formatDate(record.receiverDocDate)} \nReceiver Material: ${translatePicklistValue(
          nullSafeString(record.receiverMaterialType ?? record.materialType),
          materialTypeValues
        )}`,
        `Receiver: ${
          record.receiverLocatorType === "CS" || record.receiverLocatorType === "PS" ? record.receiverOwnerName : record.receiverCompanyName
        } (${record.receiverLocatorType === "CS" || record.receiverLocatorType === "PS" ? record.receiverOwnerCode : record.receiverCompanyCode}) \n${
          record.receiverAddress1
        } \n${nullSafeString(record.receiverAddress2)}\nStation: ${record.receiverStationName} (${
          record.receiverStateName
        }) \t\tGST No: ${nullSafeString(record.receiverGSTNo)} \nLocator: ${record.receiverLocatorName} (${
          record.receiverLocatorCode
        }) \t\tLocator Type: ${translatePicklistValue(record.receiverLocatorType, locatorTypeValues)} \nVendor: ${nullSafeString(
          record.receiverCompanyName
        )} (${nullSafeString(record.receiverCompanyCode)}) \nProject: ${nullSafeString(record.receiverProjectName)} (${nullSafeString(
          record.receiverProjectCode
        )}) \t\tVirtual Locator: ${nullSafeString(record.receiverVirtualLocator)}  `,
      ],
    ];

    dtHead = [["SN", "Item Id", "Description Of Material", "Part Code", "Out Qty", "UOM", "Value", "MIR No", "Row No"]];

    details = record.details.map((row, index) => {
      return [
        index + 1,
        row.itemId,
        row.itemName,
        row.itemCustCode,
        safeConvertToFixed(row.totalQuantity, 1),
        row.uom,
        safeConvertToFixed(row.totalAmount, 2),
        row.mirId,
        row.mirRowId,
      ];
    });

    let totalDetailQuantity = record.details.reduce((total, detail) => {
      return total + parseFloat(detail.totalQuantity);
    }, 0);
    totalDetailQuantity = Math.round(totalDetailQuantity * 100) / 100;
    details.push(["", "", "", "Totals", safeConvertToFixed(totalDetailQuantity, 1), "", safeConvertToFixed(record.totalValue, 2), "", ""]);

    remarks = [[`Remarks: ${nullSafeString(record.remarks)}`]];

    otherValues = [
      [
        `Total Value: ${safeConvertToFixed(record.totalValue, 2)} \nTotal Packages: ${record.totalPkgs} \nStatus: ${
          record.cancelled ? "Cancelled" : record.delivered ? "Delivered" : "Delivery Pending"
        }\t\t\t\t\t\t\t\t\t\t`,
        `Transport Mode: ${translatePicklistValue(record.transportMode, transportModeValues)} \nTransport Vendor: ${
          record.vendorName
        } (${nullSafeString(record.vendorCode)}) \nVehicle No: ${nullSafeString(record.vehicleNo)}\t\t\t\t\t\t\t\t\t\t`,
        `Consignment Note: ${nullSafeString(record.consignmentNote)}\nEway Bill: ${nullSafeString(record.ewayBillNo)}\t\t\t\t\t\t\t\t\t\t`,
      ],
      [
        `Delivery Date: ${formatDate(record.deliveryDate)}\t\t\t\t\t\t\t\t\t\t`,
        `Receiver Person: ${nullSafeString(record.receiverPerson)}\t\t\t\t\t\t\t\t\t\t`,
        `Receiver Mobile: ${nullSafeString(record.receiverMobile)}\t\t\t\t\t\t\t\t\t\t`,
      ],
    ];

    return {
      rows: [
        { headers: title, halign: "center" },
        { body: senderReceiverData },
        { headers: dtHead, body: details, halign: "center" },
        { body: remarks },
        { body: otherValues },
      ],
      footer: "ONLY FOR INTERNAL USE",
    };
  } else if (recordType === "cb-sheet") {
    title = [["CONSUMPTION BOOK SHEET"]];
    senderReceiverData = [
      [
        `CBS No: ${record.id} \nCBS Date: ${formatDate(record.docDate)} \nProject Type: ${translatePicklistValue(
          record.projectType,
          projectTypeValues
        )}`,
        `Contractor: ${record.docCompanyName} (${record.docCompanyCode}) \nLocator: ${record.docLocatorName} (${
          record.docLocatorCode
        }) \t\tLocator Type: ${translatePicklistValue(record.docLocatorType, locatorTypeValues)}\nOwner: ${record.docOwnerName} (${
          record.docOwnerCode
        }) \t\tProject: ${record.projectName} (${record.projectCode})\nStation: ${record.docStationName} (${
          record.docStateName
        }) \t\tGST No: ${nullSafeString(record.docGSTNo)}`,
      ],
      [
        `Project Type: ${translatePicklistValue(record.consuProjectType, projectTypeValues)}\nMaterial Type: ${translatePicklistValue(
          record.materialType,
          materialTypeValues
        )}\n${record.consuProjectType === "FTTH" ? `CWO Id:` : `Job Id:`} ${record.consuProjectType === "FTTH" ? record.cwoId : record.jobId} \n${
          record.consuProjectType === "FTTH" ? `CWO Date:` : `PO No:`
        } ${record.consuProjectType === "FTTH" ? formatDate(record.cwoDate) : record.poNo}`,
        `Consumption: ${record.consuOwnerName} (${record.consuOwnerCode})\nProject: ${record.consuProjectName} (${
          record.consuProjectCode
        }) \t\tVirtual Locator: ${nullSafeString(record.consuVirtualLocator)}\n${record.consuProjectType === "FTTH" ? `CWO No:` : `Site Id:`} ${
          record.consuProjectType === "FTTH" ? record.cwoNo : record.siteId
        }\n${record.consuProjectType === "FTTH" ? `CWO Type:` : `Job Type:`} ${translatePicklistValue(
          record.consuProjectType === "FTTH" ? record.cwoType : record.jobType,
          workTypeValues
        )}  \t\t${record.consuProjectType === "FTTH" ? `Work Area:` : ``} ${
          record.consuProjectType === "FTTH" ? record.workArea : ``
        }\nRoute Length: ${record.routeLength} \t\t${record.consuProjectType === "FTTH" ? `Home Pass:` : ``} ${
          record.consuProjectType === "FTTH" ? record.homePass : ``
        }`,
      ],
    ];

    dtHead = [["SN", "Item Id", "Description Of Material", "Part Code", "Out Qty", "UOM", "Value"]];

    details = record.details.map((row, index) => {
      return [
        index + 1,
        row.itemId,
        row.itemName,
        row.itemCustCode,
        safeConvertToFixed(row.totalQuantity, 1),
        row.uom,
        safeConvertToFixed(row.totalAmount, 2),
      ];
    });

    let totalDetailQuantity = record.details.reduce((total, detail) => {
      return total + parseFloat(detail.totalQuantity);
    }, 0);

    let totalDetailAmount = record.details.reduce((total, detail) => {
      return total + parseFloat(detail.totalAmount);
    }, 0);

    totalDetailQuantity = Math.round(totalDetailQuantity * 100) / 100;
    totalDetailAmount = Math.round(totalDetailAmount * 100) / 100;

    details.push(["", "", "", "Totals", safeConvertToFixed(totalDetailQuantity, 1), "", safeConvertToFixed(totalDetailAmount, 2)]);

    remarks = [[`Remarks: ${nullSafeString(record.remarks)}`]];

    otherValues = [
      [
        `Partner Inv No: ${nullSafeString(record.partnerInvNo)} \nPartner Inv Date: ${formatDate(
          record.partnerInvDate
        )}\t\t\t\t\t\t\t\t\t`,
        `Contractor Inv No: ${nullSafeString(record.contractorInvNo)} \nContractor Inv Date: ${formatDate(
          record.contractorInvDate
        )}\t\t\t\t\t\t\t\t\t`,
        `Closure No: ${nullSafeString(record.closureId)} \nClosure Date: ${
          record.closureId === null ? `` : formatDate(record.closureDate)
        }\t\t\t\t\t\t\t\t\t`,
        `Status: ${record.submit ? "Submitted" : record.cancelled ? "Cancelled" : "Not Submitted"}\t\t\t\t\t\t\t\t\t`,
      ],
    ];

    return {
      rows: [
        { headers: title, halign: "center" },
        { body: senderReceiverData },
        { headers: dtHead, body: details, halign: "center" },
        { body: remarks, halign: "left" },
        { body: otherValues },
      ],
      footer: "ONLY FOR INTERNAL USE",
    };
  }

  return {};
};

export const generatePDF = (generateContent: () => any, sameTab = false) => {
  let content = generateContent();
  // Create a new jsPDF instance
  let pdf: any = new jsPDF({
    orientation: "landscape", // Set orientation to portrait
    unit: "pt", // Use pixels as units
    format: "a4", // Set page format to A4
  });

  if (!_.isEmpty(content.rows)) {
    content.rows.forEach((row, index) => {
      pdf.autoTable({
        head: row.headers,
        startY: index === 0 ? 50 : pdf.lastAutoTable.finalY,
        body: row.body,
        ...options,
        didParseCell: function (data) {
          data.cell.styles.valign = "top"; // Vertical alignment
          data.cell.styles.halign = row.halign ? row.halign : "left"; // Horizontal alignment
        },
      });
    });
  }
  // Add the table to the PDF

  pdf.internal.scaleFactor = 1;

  const pageCount = pdf.internal.getNumberOfPages();
  for (let i = 1; i <= pageCount; i++) {
    let pageSize = pdf.internal.pageSize;
    pdf.setPage(i);
    pdf.setFontSize(10);
    pdf.text(`Page ${i} of ${pageCount}`, pageSize.getWidth() - 100, pageSize.getHeight() - 10);
    if (content.footer) {
      const textWidth = (pdf.getStringUnitWidth(content.footer) * pdf.internal.getFontSize()) / pdf.internal.scaleFactor;
      const textX = (pageSize.getWidth() - textWidth) / 2;
      pdf.text(content.footer, textX, pageSize.getHeight() - 10);
    }
  }
  if (sameTab) {
    const pdfDataUri2 = pdf.output("bloburl");
    window.location.href = pdfDataUri2;
  } else {
    const pdfDataUri = pdf.output("datauristring");
    // Open the PDF in a new tab
    const newTab = window.open();
    newTab.document.write('<iframe width="100%" height="100%" src="' + pdfDataUri + '"></iframe>');
  }
};
