import withAuth from "../../../hocs/withAuth";
import _ from "lodash";
import RecordAdditionForm from "../../recordStandardForms/RecordAdditionForm";
import { getOpeningStockDateString } from "../../../services/dateUtils";
import dayjs from "../../../config/dayjsConfig";
import { getToday } from "../../../services/dateUtils";

export const getDocDate = (record) => {
  return record.createAt ? dayjs(record.createdAt).startOf("day") : getToday();
};

function MaterialInwardForm() {
  const isValidRecord = (record, setFormErrors, setError) => {
    if (!record) {
      return false;
    }
    const totalValues = _.isEmpty(record.details)
      ? 0
      : record.details
          .filter((detail) => !detail.deleted)
          .reduce((total, detail) => {
            return total + parseFloat(detail.basicAmount);
          }, 0);
    const totalDetailValues = Math.round(totalValues * 100) / 100;
    let isTotalValid = totalDetailValues === parseFloat(record.basicValue);
    if (!isTotalValid) {
      setFormErrors((formError) => {
        formError["basicValue"] = `Should match sum of all Basic Amounts ${totalDetailValues}`;
        return { ...formError };
      });
      setError(`Basic value should match sum of all Basic Amounts in detail ${totalDetailValues}`);
      return false;
    } else {
      setFormErrors((formError) => {
        formError["inventoryValue"] = "";
        return { ...formError };
      });
      setError("");
    }
    return true;
  };

  const handleEntitySpecificChange = ({ record, name, value, type, checked, setFormErrors, handleRecordChange }) => {
    if (name === "transactionType") {
      if (value === "OS" && (!record["docDate"] || record["docDate"] !== getOpeningStockDateString())) {
        handleRecordChange({
          target: { name: "docDate", value: getOpeningStockDateString(), type, checked },
        });
      } else if (value !== "OS" && (!record["docDate"] || record["docDate"] === getOpeningStockDateString())) {
        handleRecordChange({
          target: { name: "docDate", value: getDocDate(record), type, checked },
        });
      }
    } else if (name === "projectType") {
      if (value === "FTTH" && record.docLocatorType === "CS") {
        handleRecordChange({
          target: { name: "receiverDocType", value: "CO", type, checked },
        });
      } else {
        handleRecordChange({
          target: { name: "receiverDocType", value: "NA", type, checked },
        });
      }
    }
  };

  return (
    <RecordAdditionForm
      formMinWidth={"82vw"}
      entityType="material-inward"
      isValidEntityRecord={isValidRecord}
      handleEntitySpecificChange={handleEntitySpecificChange}
      allowDelete={false}
      formDisabled={(record) => record && (record.transactionType === "IS" || record.transactionType === "PS" || record.transactionType === "CS")}
    />
  );
}

export default withAuth(MaterialInwardForm);
