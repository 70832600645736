import { createContext, useContext, useState, ReactNode } from "react";
import { Configs } from "./configHolder";

// Define AppEnv type
export type AppEnv = {
  configs: Configs;
  accessibleLocatorCodes?: string[];
};

// Contexts for AppEnv and its setter
const AppEnvContext = createContext<AppEnv | null>(null);
const AppEnvSetterContext = createContext<((env: AppEnv) => void) | null>(null);

// Custom hook to access the AppEnv
export const useAppEnv = (): AppEnv => {
  const appEnv = useContext(AppEnvContext);
  if (appEnv === null) {
    throw new Error("useAppEnv must be used within an AppEnvProvider");
  }
  return appEnv;
};

// Custom hook to set the AppEnv (once, after fetching from backend)
export const useSetAppEnv = (): ((env: AppEnv) => void) => {
  const setEnv = useContext(AppEnvSetterContext);
  if (setEnv === null) {
    throw new Error("useSetAppEnv must be used within an AppEnvProvider");
  }
  return setEnv;
};

// Provider component that holds the AppEnv state
export const AppEnvProvider = ({ children }: { children: ReactNode }) => {
  const [appEnv, setAppEnv] = useState<AppEnv>({ configs: {} });

  return (
    <AppEnvContext.Provider value={appEnv}>
      <AppEnvSetterContext.Provider value={setAppEnv}>{children}</AppEnvSetterContext.Provider>
    </AppEnvContext.Provider>
  );
};
