import { AppEnv, useAppEnv } from "./AppEnvProvider";

declare global {
  interface Window {
    APP_ENV: AppEnv | null;
  }
}

export enum ConfigKey {
  OPENING_STOCK_DATE = "opening_stock_date",
  LOCATOR_ACCESS_CONTROL_ENABLED = "locator_access_control_enabled",
  OWNER_FLAG_EDIT_ENABLED = "owner_flag_edit_enabled",
  PARTNER_FLAG_EDIT_ENABLED = "partner_flag_edit_enabled",
}

let appEnvCache: AppEnv | null = null;

// Utility function to access the configs outside of React components
export const getConfigValue = (configKey: ConfigKey, defaultValue = "") => {
  if (!appEnvCache) {
    return defaultValue;
  }
  return appEnvCache.configs[configKey] ?? defaultValue;
};

export const waitForAppEnv = async (): Promise<void> => {
  const maxRetries = 100; // Number of retries
  let retryCount = 0;

  // Poll every 100ms
  while (!appEnvCache && retryCount < maxRetries) {
    await new Promise((resolve) => setTimeout(resolve, 100));
    retryCount++;
  }

  if (!appEnvCache) {
    throw new Error("AppEnv not initialized after waiting");
  }
};

export const getConfigs = () => {
  if (!appEnvCache) {
    return {};
  }
  return appEnvCache.configs;
};

export const getAccessibleLocatorCodes = (): string[] | null => {
  if (!appEnvCache) {
    return [];
  }
  return appEnvCache.accessibleLocatorCodes || null;
};

// Initialization function to be called at the start of the app
export const initAppEnv = (appEnv: AppEnv) => {
  appEnvCache = appEnv;
  window.APP_ENV = appEnv;
};

// Custom hook to get a specific config value
export const useConfigValue = (configKey: ConfigKey) => {
  const appEnv = useAppEnv();
  return appEnv.configs[configKey];
};

// Custom hook to get all the configs
export const useConfigs = () => {
  const appEnv = useAppEnv();
  return appEnv.configs;
};

export type Configs = {
  [configKey in ConfigKey]?: boolean | string[] | object;
};
