import { Field } from "../types/field";
import { Column } from "../types/column";
import { formatDate } from "../services/dateUtils";
import { translatePicklistValue } from "../services/fieldsUtils";
import { ConfigKey } from "../config/configHolder";

export const dataAccessTypes = [
  { value: 0, label: "None" },
  { value: 1, label: "Read" },
  { value: 2, label: "Read-Write" },
];

export const sharedLocatorTypeValues = [
  { value: "PS", label: "Partner Store" },
  { value: "CS", label: "Contractor Store" },
];

export const userTypesWithLables = [
  { value: 0, label: "Standard" },
  { value: 1, label: "Admin" },
];

export const sharedLocatorFields: Field[] = [
  {
    name: "locatorType",
    label: "Locator Type",
    type: "picklist",
    values: sharedLocatorTypeValues,
    defaultValue: "PS",
    props: {
      widthFactor: 0.8,
    },
    linkedFields: [
      {
        name: "contractorName",
        filteredValues: [
          {
            filter: {
              type: "IN",
              field: "locatorType",
              values: ["PS"],
            },
            value: "ALL",
          },
        ],
        value: "",
      },
      {
        name: "companyCode",
        value: "",
        filteredValues: [
          {
            filter: {
              type: "IN",
              field: "locatorType",
              values: ["PS"],
            },
            value: "ALL",
          },
        ],
      },
      {
        name: "stateName",
        value: "",
      },
      {
        name: "stateCode",
        value: "",
      },
      {
        name: "locatorName",
        value: "",
      },
      {
        name: "locatorCode",
        value: "",
      },
    ],
  },
  {
    name: "contractorName",
    label: "Contractor Name",
    type: "lookup",
    lookupType: "company",
    lookupCategory: "locatorAccessContractor",
    props: {
      widthFactor: 1.4,
    },
    defaultValue: "ALL",
    setDefaultOnDisable: true,
    lookupParams: [
      {
        key: "locatorType",
        resolveValue: true,
        valueFieldName: "locatorType",
      },
    ],
    disability: {
      type: "OR",
      filters: [
        {
          type: "IN",
          field: "locatorType",
          values: ["PS"],
        },
        {
          type: "MISSING",
          field: "locatorType",
        },
      ],
    },
    linkedFields: [
      {
        name: "stateName",
        value: "",
      },
      {
        name: "stateCode",
        value: "",
      },
      {
        name: "locatorName",
        value: "",
      },
      {
        name: "locatorCode",
        value: "",
      },
    ],
    dependentFields: [
      {
        additionalFieldName: "details",
        lookupFieldName: "id",
        fieldName: "companyCode",
      },
    ],
  },
  {
    name: "companyCode",
    label: "Code",
    type: "text",
    autoFilled: true,
    defaultValue: "ALL",
    props: {
      widthFactor: 0.5,
    },
    setDefaultOnDisable: true,
    disability: {
      type: "OR",
      filters: [
        {
          type: "IN",
          field: "locatorType",
          values: ["PS"],
        },
      ],
    },
  },
  {
    name: "stateName",
    label: "State Name",
    type: "lookup",
    lookupType: "state",
    lookupCategory: "locatorAccessState",
    props: {
      widthFactor: 1.4,
    },
    disability: {
      type: "OR",
      filters: [
        {
          type: "MISSING",
          field: "contractorName",
        },
      ],
    },
    lookupParams: [
      {
        key: "companyCode",
        resolveValue: true,
        valueFieldName: "companyCode",
      },
      {
        key: "locatorType",
        resolveValue: true,
        valueFieldName: "locatorType",
      },
    ],
    linkedFields: [
      {
        name: "locatorName",
        value: "",
      },
      {
        name: "locatorCode",
        value: "",
      },
    ],
    dependentFields: [
      {
        additionalFieldName: "details",
        lookupFieldName: "id",
        fieldName: "stateCode",
      },
    ],
  },
  {
    name: "stateCode",
    label: "State Code",
    type: "text",
    autoFilled: true,
    props: {
      widthFactor: 0.5,
    },
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "lookup",
    lookupType: "locator",
    lookupCategory: "locatorAccessLocator",
    disability: {
      type: "OR",
      filters: [
        {
          type: "MISSING",
          field: "stateName",
        },
      ],
    },
    lookupParams: [
      {
        key: "companyCode",
        resolveValue: true,
        valueFieldName: "companyCode",
      },
      {
        key: "companyCode",
        resolveValue: true,
        valueFieldName: "companyCode",
      },
      {
        key: "stateCode",
        resolveValue: true,
        valueFieldName: "stateCode",
      },
      {
        key: "locatorType",
        resolveValue: true,
        valueFieldName: "locatorType",
      },
    ],
    props: {
      widthFactor: 1.4,
    },
    dependentFields: [
      {
        additionalFieldName: "details",
        lookupFieldName: "id",
        fieldName: "locatorCode",
      },
    ],
  },
  {
    name: "locatorCode",
    label: "Locator Code",
    type: "text",
    autoFilled: true,
    props: {
      widthFactor: 0.5,
    },
  },
];

export const userFields: Field[] = [
  {
    name: "section1",
    type: "formSection",
    alignment: "column",
    childFields: [
      {
        name: "infoSection",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
        },
        childFields: [
          {
            name: "userId",
            label: "User Id",
            type: "text",
          },
          {
            name: "name",
            label: "Name",
            type: "text",
            allowUpdate: true,
          },
          {
            name: "email",
            label: "Email",
            type: "text",
            allowUpdate: true,
          },
          {
            name: "mobile",
            label: "Mobile",
            type: "text",
            allowUpdate: true,
          },
        ],
      },
      {
        name: "section1",
        type: "formSection",
        alignment: "column",
        childFields: [
          {
            name: "userType",
            label: "User Type",
            type: "picklist",
            values: userTypesWithLables,
            allowUpdate: true,
            props: {
              width: "25vw",
            },
          },
          {
            name: "section1",
            type: "formSection",
            childFields: [
              {
                name: "masterAccessType",
                label: "Master Data Access",
                type: "picklist",
                allowUpdate: true,
                values: dataAccessTypes,
                // props: {
                //   width: "10vw",
                // },
              },
              {
                name: "transactionAccessType",
                label: "Transaction Data Access",
                type: "picklist",
                allowUpdate: true,
                values: dataAccessTypes,
                // props: {
                //   width: "10vw",
                // },
              },
            ],
          },
          {
            name: "section1",
            type: "formSection",
            childFields: [
              {
                name: "approvedBy",
                label: "Last Approved By",
                type: "text",
                displayOnly: true,
                allowNull: true,
                // props: {
                //   width: "10vw",
                // },
              },
              {
                name: "approvedAt",
                label: "Last Approval Date",
                type: "date",
                displayOnly: true,
                allowNull: true,
                // props: {
                //   width: "10vw",
                // },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    alignment: "row",
    childFields: [
      {
        name: "allAccess",
        label: "All Locator Access",
        type: "checkbox",
        allowUpdate: true,
        defaultValue: true,
        disability: {
          type: "OR",
          filters: [
            {
              type: "EXISTS",
              field: "locatorAccessConfig",
            },
            {
              type: "IN",
              field: ConfigKey.LOCATOR_ACCESS_CONTROL_ENABLED,
              values: [false],
            },
          ],
        },
      },
      {
        name: "approved",
        label: "Approved",
        type: "checkbox",
        allowUpdate: true,
      },
    ],
  },
  {
    name: "locatorAccessConfig",
    label: "Shared Locators",
    type: "formTable",
    allowUpdate: true,
    // allowNull: true,
    tableFields: sharedLocatorFields,
    props: {
      width: "82vw",
    },
    visibility: {
      type: "IN",
      field: ConfigKey.LOCATOR_ACCESS_CONTROL_ENABLED,
      values: [true],
    },
    disability: {
      type: "OR",
      filters: [
        {
          type: "IN",
          field: "allAccess",
          values: [true],
        },
      ],
    },
  },
];

export const myProfileFields: Field[] = [
  {
    name: "userId",
    label: "User Id",
    type: "text",
  },
  {
    name: "name",
    label: "Name",
    type: "text",
    allowUpdate: true,
  },
  {
    name: "email",
    label: "Email",
    type: "text",
    allowUpdate: true,
  },
  {
    name: "mobile",
    label: "Mobile",
    type: "text",
    allowUpdate: true,
  },
  {
    name: "userType",
    label: "User Type",
    type: "picklist",
    values: userTypesWithLables,
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "masterAccessType",
        label: "Master Data Access",
        type: "picklist",
        values: dataAccessTypes,
        props: {
          width: "12vw",
        },
      },
      {
        name: "transactionAccessType",
        label: "Transaction Data Access",
        type: "picklist",
        values: dataAccessTypes,
        props: {
          width: "12vw",
        },
      },
    ],
  },
  {
    name: "approved",
    label: "Approved",
    type: "checkbox",
  },
];

export const userColumns = [
  {
    name: "name",
    label: "Name",
    type: "text",
    minWidth: 150,
  },
  {
    name: "userId",
    label: "User Id",
    type: "text",
  },
  {
    name: "email",
    label: "Email",
    type: "text",
  },
  {
    name: "mobile",
    label: "Mobile",
    type: "text",
  },
  {
    name: "userType",
    label: "User Type",
    format: (value) => {
      const userType = userTypesWithLables.find((userType) => userType.value === value);
      return userType ? userType.label : "Unknown";
    },
  },
  {
    name: "masterAccessType",
    label: "Master Access",
    type: "text",
    format: (value) => translatePicklistValue(value, dataAccessTypes),
  },
  {
    name: "transactionAccessType",
    label: "Transaction Access",
    type: "text",
    format: (value) => translatePicklistValue(value, dataAccessTypes),
  },
  {
    name: "allAccess",
    label: "All Locators",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "approved",
    label: "Approved",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "approvedBy",
    label: "Last Approval",
    type: "text",
  },
  {
    name: "approvedAt",
    label: "Last Approval",
    type: "date",
    format: (value) => formatDate(value),
  },
];

export const userSearchFields = [
  {
    name: "name",
    label: "Name",
    type: "text",
  },
  {
    name: "userId",
    label: "User Id",
    type: "text",
  },
  {
    name: "email",
    label: "Email",
    type: "text",
  },
  {
    name: "userType",
    label: "User Type",
    type: "picklist",
    defaultValue: null,
    values: userTypesWithLables,
  },
  {
    name: "allAccess",
    label: "All Locator Access",
    type: "picklist",
    defaultValue: null,
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
  {
    name: "approved",
    label: "Approved",
    type: "picklist",
    defaultValue: null,
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
];

export const userExcelColumns: Column[] = [
  {
    name: "name",
    label: "Name",
    type: "text",
  },
  {
    name: "userId",
    label: "User Id",
    type: "text",
  },
  {
    name: "email",
    label: "Email",
    type: "text",
  },
  {
    name: "mobile",
    label: "Mobile",
    type: "text",
  },
  {
    name: "userType",
    label: "User Type",
    type: "picklist",
    values: userTypesWithLables,
  },
  {
    name: "masterAccessType",
    label: "Master Access",
    type: "picklist",
    values: dataAccessTypes,
  },
  {
    name: "transactionAccessType",
    label: "Transaction Access",
    type: "picklist",
    values: dataAccessTypes,
  },
  {
    name: "approved",
    label: "Approved",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "allAccess",
    label: "All Locator Access",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "createdAt",
    label: "Creation Date",
    type: "date",
  },
  {
    name: "approvedBy",
    label: "Last Approved By",
    type: "text",
  },
  {
    name: "approvedAt",
    label: "Last Approval Date",
    type: "date",
  },
];

export const userDetailExcelColumns: Column[] = [
  {
    name: "userId",
    label: "User Id",
    type: "text",
  },
  {
    name: "name",
    label: "Name",
    type: "text",
  },
  {
    name: "email",
    label: "Email",
    type: "text",
  },
  {
    name: "locatorType",
    label: "Locator Type",
    type: "picklist",
    values: sharedLocatorTypeValues,
  },
  {
    name: "contractorName",
    label: "Contractor Name",
    type: "text",
  },
  {
    name: "companyCode",
    label: "Code",
    type: "text",
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "stateCode",
    label: "State Code",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "locatorCode",
    label: "Locator Code",
    type: "text",
  },
];
