import { Filter } from "../types/filter";

const getEntitySpecificFilters = (recordType, detailRecordType) => {
  if (recordType === "gate-pass" && detailRecordType === "material-outward-dtl") {
    return {
      type: "EQUALS",
      field: "gpFlg",
      values: true,
    };
  }
  return {};
};

export const getRecordsSearchRequestFilters = (searchRequest, searchFieldNameVsFieldMap, recordType, detailRecordType?) => {
  let filters = [];

  for (let key in searchRequest) {
    if (typeof searchFieldNameVsFieldMap[key] == "undefined" || searchRequest[key] == null || searchRequest[key] === "") {
      continue;
    }
    if (searchFieldNameVsFieldMap[key].foreignEntityType) {
      //handled by separate function, to clean up and combine
      continue;
    }
    if (searchFieldNameVsFieldMap[key].type === "text") {
      filters.push({
        filters: [],
        field: key,
        values: [searchRequest[key]],
        type: "CONTAINS",
      });
    } else if (searchFieldNameVsFieldMap[key].type === "number") {
      filters.push({
        filters: [],
        field: key,
        values: [searchRequest[key]],
        type: "EQUALS",
      });
    } else {
      filters.push({
        filters: [],
        field: key,
        values: [searchRequest[key]],
        type: "IN",
      });
    }
  }
  filters.push(getEntitySpecificFilters(recordType, detailRecordType));
  return { filters, field: null, values: [], type: "AND" };
};

export const getRecordsSearchRequestForeignFilters = (searchRequest, searchFieldNameVsFieldMap): Map<String, Filter> => {
  let filters = new Map<String, Filter[]>();

  for (let key in searchRequest) {
    if (typeof searchFieldNameVsFieldMap[key] == "undefined" || searchRequest[key] == null || searchRequest[key] === "") {
      continue;
    }
    if (!searchFieldNameVsFieldMap[key].foreignEntityType) {
      //handled by separate function, to clean up and combine
      continue;
    }
    if (searchFieldNameVsFieldMap[key].type === "text") {
      filters.set(searchFieldNameVsFieldMap[key].foreignEntityType, [
        ...(filters.get(searchFieldNameVsFieldMap[key].foreignEntityType) || []),
        {
          field: key,
          values: [searchRequest[key]],
          type: "CONTAINS",
        },
      ]);
    } else if (searchFieldNameVsFieldMap[key].type === "number") {
      filters.set(searchFieldNameVsFieldMap[key].foreignEntityType, [
        ...(filters.get(searchFieldNameVsFieldMap[key].foreignEntityType) || []),
        {
          field: key,
          values: [searchRequest[key]],
          type: "EQUALS",
        },
      ]);
    } else {
      filters.set(searchFieldNameVsFieldMap[key].foreignEntityType, [
        ...(filters.get(searchFieldNameVsFieldMap[key].foreignEntityType) || []),
        {
          field: key,
          values: [searchRequest[key]],
          type: "IN",
        },
      ]);
    }
  }

  let combinedFilters = new Map<String, Filter>();
  for (let [foreignEntityType, entityFilters] of Array.from(filters)) {
    combinedFilters.set(foreignEntityType, { filters: entityFilters, type: "AND" });
  }
  return combinedFilters;
};
