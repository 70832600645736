import { Field } from "../types/field";
import { translatePicklistValue } from "../services/fieldsUtils";

export const projectTypeValues = [
  { value: "FF", label: "FF  Work" },
  { value: "FTTH", label: "FTTH Work" },
  { value: "NFIM", label: "NFIM Store" },
];

export const projectFields: Field[] = [
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "projectType",
        label: "Project Type",
        type: "picklist",
        defaultValue: "FF",
        values: projectTypeValues,
        props: {
          width: "17vw",
        },
        linkedFields: [
          {
            name: "virtualLocator",
            value: "",
          },
          {
            name: "routeLength",
            value: "",
          },
        ],
      },
      {
        name: "projectCode",
        label: "Project",
        type: "text",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "7vw",
        },
      },
    ],
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
    allowUpdate: true,
  },

  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "stateName",
        label: "State Name",
        type: "lookup",
        lookupType: "state",
        defaultValue: null,
        allowUpdate: false,
        lookupFilter: {
          type: "EQUALS",
          field: "stateCode",
          resolveValue: true,
          valueFieldName: "stateCode",
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "stateCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "gstCode",
            fieldName: "gstCode",
          },
        ],
        linkedFields: [
          {
            name: "gstNo",
            value: null,
          },
        ],
        props: {
          width: "17vw",
        },
      },
      {
        name: "stateCode",
        label: "State Code",
        type: "text",
        defaultValue: "",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "7vw",
        },
      },
    ],
  },

  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "ownerCode",
        label: "Owner Code",
        type: "text",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "7vw",
        },
      },
      {
        name: "section1",
        type: "formSection",
        childFields: [
          {
            name: "companyName",
            label: "Project Owner Company",
            type: "lookup",
            allowUpdate: false,
            lookupType: "company",
            lookupCategory:"projectOwner",
            lookupParams: [
              {
                key: "projectType",
                resolveValue: true,
                valueFieldName: "projectType",
              },
            ],
            // lookupFilter: {
            //   type: "OR",
            //   filters: [
            //     {
            //       type: "AND",
            //       filters: [
            //         {
            //           type: "IN",
            //           field: "projectType",
            //           values: ["KK"],
            //         },
            //         {
            //           type: "EQUALS",
            //           field: "ownerFlg",
            //           values: [true],
            //         },
            //       ],
            //     },
            //     {
            //       type: "AND",
            //       filters: [
            //         {
            //           type: "IN",
            //           field: "projectType",
            //           values: ["NFIM"],
            //         },
            //         {
            //           type: "EQUALS",
            //           field: "partnerFlg",
            //           values: [true],
            //         },
            //       ],
            //     },
            //   ],
            // },
            dependentFields: [
              {
                lookupFieldName: "id",
                fieldName: "ownerCode",
              },
              {
                additionalFieldName: "details",
                lookupFieldName: "gstNo",
                fieldName: "gstNo",
              },
            ],
            props: {
              width: "17vw",
            },
            linkedFields: [
              {
                name: "gstNo",
                value: null,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "gstNo",
        label: "GST Number",
        type: "lookup",
        lookupType: "companyGst",
        lookupCategory: "locatorCompany",
        defaultValue: null,
        props: {
          width: "17vw",
        },
        disability: {
          type: "OR",
          filters: [
            { type: "MISSING", field: "companyName" },
            { type: "MISSING", field: "stateName" },
          ],
        },
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "EQUALS",
              field: "companyCode",
              resolveValue: true,
              valueFieldName: "ownerCode",
            },
            {
              type: "EQUALS",
              field: "gstCode",
              resolveValue: true,
              valueFieldName: "gstCode",
            },
          ],
        },
      },
      {
        name: "gstCode",
        label: "GST Code",
        type: "text",
        props: {
          width: "7vw",
        },
        autoFilled: true,
        allowNull: true,
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    visibility: { type: "NIN", field: "projectType", values: ["NFIM"] },
    childFields: [
      {
        name: "virtualLocator",
        label: "Virtual Locator",
        type: "text",
        defaultValue: "",
        allowUpdate: false,
        props: {
          width: "17vw",
        },
        disability: {
          type: "IN",
          field: "projectType",
          values: ["NFIM"],
        },
      },
      {
        name: "routeLength",
        label: "Route (Mtr)",
        type: "number",
        allowNull: false,
        allowUpdate: true,
        defaultValue: "",
        props: {
          width: "7vw",
        },
        disability: {
          type: "IN",
          field: "projectType",
          values: ["FTTH","NFIM"],
        },
      },
    ],
  },
  {
    name: "remarks",
    label: "Remarks",
    type: "text",
    allowUpdate: true,
    allowNull: true,
    props: {
      width: "25vw",
    },
  },
  {
    name: "oprojectName",
    label: "Original Project Name",
    type: "text",
    autoFilled: true,
    allowNull: true,
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "active",
        label: "Active",
        type: "checkbox",
        defaultValue: false,
        allowUpdate: true,
      },
      {
        name: "closed",
        label: "Closed",
        type: "checkbox",
        allowUpdate: false,
        autoFilled: true,
        defaultValue: false,
      },
    ],
  },
];

export const projectColumns = [
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "ownerCode",
    label: "Owner Code",
    type: "text",
  },
  {
    name: "gstNo",
    label: "GST No",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "closed",
    label: "Closed",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const projectExcelColumns = [
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "ownerCode",
    label: "Owner Code",
    type: "text",
  },
  {
    name: "companyName",
    label: "Owner Name",
    type: "text",
  },
  {
    name: "gstNo",
    label: "GST No",
    type: "text",
  },
  {
    name: "routeLength",
    label: "Route Length (Mtr)",
    type: "number",
  },
  {
    name: "remarks",
    label: "Remarks",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "closed",
    label: "Closed",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const projectSearchColumns = [
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "picklist",
    defaultValue: "",
    values: projectTypeValues,
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
  {
    name: "closed",
    label: "Closed",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
];
